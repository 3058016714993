.dot-loader-container {

    transition: all 1 ease !important;

    position: absolute;

    background: #000;
    display: flex;
    width: inherit;
    height: inherit;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    
    .dot-loader {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    
    .dot-loader div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    
    .dot-loader div:nth-child(1) {
        left: 8px;
        animation: dot-loader1 0.6s infinite;
    }
    
    .dot-loader div:nth-child(2) {
        left: 8px;
        animation: dot-loader2 0.6s infinite;
    }
    
    .dot-loader div:nth-child(3) {
        left: 32px;
        animation: dot-loader2 0.6s infinite;
    }
    
    .dot-loader div:nth-child(4) {
        left: 56px;
        animation: dot-loader3 0.6s infinite;
    }
    
    @keyframes dot-loader1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    
    @keyframes dot-loader3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    
    @keyframes dot-loader2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
}